import React from "react"
import Hero from "../components/hero"
import Layout from '../components/layout-default'
import '../styles/events.scss'
import Omar from "../images/OmarWorkshop.jpg"
import Button from "../components/button" 


const SocialEvents = ({ data }) => (
  <Layout
    title="Salsa &amp; Bachata Social Events in Orange County | RF Dance"
    description="Local dance events in the OC." 
    pathname="workshops"
    img={Omar}>
    <Hero 
      titleRed="RF"
      titleWhite="Events"/>
      
      <section className="content">
        <div className="content-block text-center">
          <div className="container">
            <h2>We're here to present you the best latin dance events in the OC.</h2>
            <p>Our dancers know where the best local events are going down, every week. We're only interested in the local events that play good music, are safe, and have a great turn out of talented dancers. Our goal here is to share that underground knowledge with you, so you can join in and have a great time with us.</p>
          </div>
        </div>

        <hr className="divider" />

        <div className="content-block">
          <div className="events">
            <iframe 
              src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23039BE5&amp;ctz=America%2FLos_Angeles&amp;src=b2NyaHl0aG1pY2Z1c2lvbkBnbWFpbC5jb20&amp;color=%237986CB&amp;showTitle=0&amp;showCalendars=1&amp;showPrint=0"
              width="800"
              height="600"
              frameborder="0"
              scrolling="no"
              className="calendar calendar-standard"/>
            <iframe 
              src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23039BE5&amp;ctz=America%2FLos_Angeles&amp;src=b2NyaHl0aG1pY2Z1c2lvbkBnbWFpbC5jb20&amp;color=%237986CB&amp;showTitle=0&amp;showCalendars=1&amp;showPrint=0"
              width="400"
              height="600"
              frameborder="0"
              scrolling="no"
              className="calendar calendar-tablet"/>
            <iframe src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=America%2FLos_Angeles&amp;src=b2NyaHl0aG1pY2Z1c2lvbkBnbWFpbC5jb20&amp;color=%237986CB&amp;showTitle=0&amp;showPrint=0&amp;mode=AGENDA&amp;showDate=0&amp;showTabs=0&amp;showNav=1"         
              width="300"
              height="600"
              frameborder="0"
              scrolling="no"
              className=" calendar calendar-mobile"/>
            <div className="zoom-cta">
              <Button 
              type="link" 
              href="https://us04web.zoom.us/j/4976645105" 
              text="Join us on Zoom!"
              styling="isInfo"
              />
            </div>
          </div>
        </div>
      </section>
      <style jsx="jsx">{`
        .calendar {
          display: block;
          margin: 0 auto;
          border-width: 0;
        }
        .calendar.calendar-mobile,
        .calendar.calendar-tablet {
          display: none;
        }
        .zoom-cta {
          display: flex;
          justify-content: center;
          padding: 2em 0;
        }
        @media (max-width: 991px) {
          .calendar.calendar-tablet {
            display: block;
          }
          .calendar.calendar-standard {
            display: none;
          }
        }
        @media (max-width: 500px) {
          .calendar.calendar-mobile {
            display: block;
          }
          .calendar.calendar-tablet {
            display: none;
          }
        }
      `}
      </style>
  </Layout>
)

export default SocialEvents

